<template>
  <div class="modal-card form-container">
    <div class="modal-card-body">
      <div class="is-flex is-justify-content-space-between">
        <h1 class="h1-title has-text-black">Crear evento</h1>
      </div>
      <div class="is-divider m-3"></div>
      <form class="form" @submit.prevent="editCreateEvent()">
        <div>
          <label
            :class="{ 'has-text-danger': $v.name.$error }"
            class="label mt-4 mb-1"
            >*Nombre del evento</label
          >
          <b-field
            :type="{ 'is-danger': $v.name.$error }"
            :message="[
              {
                'El nombre del evento es obligatorio':
                  $v.name.$error && !$v.name.required,
              },
            ]"
          >
            <b-input
              maxlength="100"
              type="text"
              :class="{ 'is-danger': $v.name.$error }"
              v-model.trim="$v.name.$model"
              @blur="$v.name.$touch"
            >
            </b-input>
          </b-field>
        </div>
        <div>
          <label
            :class="{ 'has-text-danger': $v.categoryId.$error }"
            class="label mt-4 mb-1"
            >*Categoría</label
          >
          <b-field
            :type="{ 'is-danger': $v.categoryId.$error }"
            :message="[
              {
                'La categoría es obligatoria':
                  $v.categoryId.$error && !$v.categoryId.required,
              },
            ]"
          >
            <b-select
              :disabled="categoryOptions.length === 0"
              class="label"
              placeholder="Categoría"
              v-model.trim="$v.categoryId.$model"
              expanded
              @blur="$v.categoryId.$touch"
              @change.native="$v.categoryId.$touch"
            >
              <option
                v-for="option in categoryOptions"
                :value="option.id"
                :key="option.id"
              >
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div>
          <label
            :class="{ 'has-text-danger': $v.moderatorId.$error }"
            class="label mt-4 mb-1"
            >*Moderador</label
          >
          <b-field
            :type="{ 'is-danger': $v.moderatorId.$error }"
            :message="[
              {
                'El moderaror del evento es obligatorio':
                  $v.moderatorId.$error && !$v.moderatorId.required,
              },
            ]"
          >
            <b-autocomplete
              v-model="fullName"
              :data="dataModerator"
              placeholder="Escribe el nombre del moderador..."
              :custom-formatter="customLabel"
              :loading="isFetching"
              @typing="getModeratorData"
              @select="(option) => (selected = option)"
              @blur="$v.moderatorId.$touch"
            >
              <template slot-scope="props">
                <div class="has-text-grey">
                  <span class="has-text-weight-semibold"
                    >{{ props.option.name }}
                    {{ props.option.lastName }} - </span
                  >{{ props.option.id }}
                </div>
              </template>
            </b-autocomplete>
          </b-field>
          <p class="help">
            Escribe el nombre/apellido o correo del moderador para activar la
            lista desplegable
          </p>
        </div>
        <div class="my-4">
          <label class="label mt-4 mb-1">*Fecha y hora de inicio:</label>
          <b-field
            message="La fecha de inicio del evento debe ser mínimo 48 horas después de la fecha y hora actual."
          >
            <b-datetimepicker
              @change="checkEventCapacityAvailability"
              v-model.trim="$v.initialHour.$model"
              placeholder="Seleccione una fecha..."
              icon-pack="fas"
              icon="calendar"
              locale="es-ES"
              :min-datetime="minDatetime"
              trap-focus
              horizontal-time-picker
            >
            </b-datetimepicker>
          </b-field>
        </div>
        <div class="my-4">
          <label class="label mt-4 mb-1">*Fecha y hora de cierre:</label>
          <b-field>
            <b-datetimepicker
              @change="checkEventCapacityAvailability"
              v-model.trim="$v.finalHour.$model"
              placeholder="Seleccione una fecha..."
              icon-pack="fas"
              icon="calendar"
              locale="es-ES"
              :min-datetime="$v.initialHour.$model"
              trap-focus
              horizontal-time-picker
            >
            </b-datetimepicker>
          </b-field>
        </div>
        <div>
          <label class="label mt-4 mb-1">Fecha límite de inscripción</label>
          <b-field
            message="La fecha límite de inscripción será un día antes de la fecha del evento"
          ></b-field>
        </div>
        <div v-if="Object.keys(this.dataModalComponent).length !== 0">
          <label class="label mt-4 mb-1">URL Vídeo</label>
          <b-field>
            <b-input
              maxlength="500"
              placeholder="URL vídeo"
              type="text"
              v-model.trim="$v.videoUrl.$model"
            >
            </b-input>
          </b-field>
        </div>
        <div>
          <label
            :class="{ 'has-text-danger': $v.description.$error }"
            class="label mt-4 mb-1"
            >*Descripción</label
          >
          <b-field
            :type="{ 'is-danger': $v.description.$error }"
            :message="[
              {
                'La descripción del evento es obligatorio':
                  $v.description.$error && !$v.description.required,
              },
            ]"
          >
            <b-input
              maxlength="500"
              type="text"
              :class="{ 'is-danger': $v.description.$error }"
              v-model.trim="$v.description.$model"
              @blur="$v.description.$touch"
            ></b-input>
          </b-field>
        </div>
        <div class="my-5">
          <label class="label mb-1">*Aforo</label>
          <b-field
            :type="{ 'is-danger': $v.capacity.$error }"
            :message="[
              {
                'El aforo del evento es obligatorio':
                  $v.capacity.$error && !$v.capacity.required,
              },
            ]"
          >
            <div class="block mb-0">
              <b-radio
                v-for="(option, index) in listMaxCapacity"
                v-model.trim="$v.capacity.$model"
                name="name"
                class="label ml-2"
                size="1px solid #dbdbdbis-small"
                :disabled="option > maxCapacity"
                :key="index"
                :native-value="option"
              >
                {{ option }}
              </b-radio>
            </div>
          </b-field>
        </div>
        <button
          type="submit"
          :disabled="this.$v.$invalid"
          class="button is-fullwidth"
        >
          {{ textButton }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import eventsService from "@/services/Events";
import userService from "@/services/User";
import categoryService from "@/services/Category";

export default {
  name: "createEditEvent",
  props: {
    dataModalComponent: {
      type: Object,
      required: true,
    },
  },
  data() {
    const now = Date.now();
    const minDate = new Date(now);
    minDate.setDate(minDate.getDate() + 2);
    return {
      minDatetime: minDate,
      categoryOptions: [],
      listMaxCapacity: [],
      dataModerator: [],
      isFetching: false,
      selected: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "moderator"
      )
        ? this.dataModalComponent.moderator
        : { name: null },
      fullName: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "moderator"
      )
        ? this.dataModalComponent.moderator.name +
          " " +
          this.dataModalComponent.moderator.lastName
        : null,
      maxCapacity: 0,
      textButton:
        Object.keys(this.dataModalComponent).length === 0
          ? "Crear evento"
          : "Guardar cambios",
      name: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "name"
      )
        ? this.dataModalComponent.name
        : "",
      description: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "description"
      )
        ? this.dataModalComponent.description
        : "",
      categoryId: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "category"
      )
        ? this.dataModalComponent.category.id
        : "",
      moderatorId: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "moderator"
      )
        ? this.dataModalComponent.moderator.id
        : "",
      initialHour: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "initialHour"
      )
        ? new Date(this.dataModalComponent.initialHour)
        : this.minDatetime,
      finalHour: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "finalHour"
      )
        ? new Date(this.dataModalComponent.finalHour)
        : this.minDatetime,
      capacity: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "description"
      )
        ? this.dataModalComponent.capacity
        : null,
      videoUrl: Object.prototype.hasOwnProperty.call(
        this.dataModalComponent,
        "videoUrl"
      )
        ? this.dataModalComponent.videoUrl
        : "",
    };
  },
  validations: {
    name: { required },
    description: { required },
    categoryId: { required },
    moderatorId: { required },
    initialHour: { required },
    finalHour: { required },
    capacity: { required },
    videoUrl: {},
  },
  watch: {
    initialHour() {
      this.changeHour();
    },
    finalHour() {
      this.changeHour();
    },
    selected(val) {
      if (val === null) this.moderatorId = null;
      this.moderatorId = val.id;
    },
  },
  methods: {
    customLabel(val) {
      if (val) {
        return val.name + " " + val.lastName;
      } else {
        return "hola";
      }
    },
    changeHour() {
      this.checkEventCapacityAvailability();
      if (this.capacity) {
        if (this.maxCapacity < this.capacity) this.capacity = null;
      }
    },
    editCreateEvent() {
      if (Object.keys(this.dataModalComponent).length === 0) this.createEvent();
      else this.editEvent();
    },
    async createEvent() {
      try {
        await eventsService.newEvent({
          name: this.name,
          description: this.description,
          categoryId: this.categoryId,
          moderatorId: this.moderatorId,
          initialHour: this.initialHour,
          finalHour: this.finalHour,
          capacity: this.capacity,
        });
        this.name = "";
        this.description = "";
        this.categoryId = "";
        this.moderatorId = "";
        this.initialHour = new Date();
        this.finalHour = new Date();
        this.capacity = "";
        this.$emit("close");
        this.$buefy.toast.open({
          duration: 5000,
          message: "Evento creado correctamente",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async editEvent() {
      try {
        await eventsService.editEvent(this.dataModalComponent.id, {
          name: this.name,
          description: this.description,
          categoryId: this.categoryId,
          moderatorId: this.moderatorId,
          initialHour: this.initialHour,
          finalHour: this.finalHour,
          capacity: this.capacity,
          videoUrl: this.videoUrl || "",
        });
        this.$emit("close");
        if (this.$route.name !== "Events")
          this.$router.push({ name: "Events" });
        this.$buefy.toast.open({
          duration: 5000,
          message: "Evento editado correctamente",
          position: "is-bottom",
          type: "is-success",
        });
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message: e.response.data.message,
          position: "is-bottom",
          type: "is-danger",
        });
      }
    },
    async getCategories() {
      try {
        this.categoryOptions =
          await categoryService.getCategoriesWhitSubcategory();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getModeratorData(word) {
      try {
        this.dataModerator = [];
        if (!word) return;
        else {
          this.isFetching = true;
          this.dataModerator = await userService.searchUser(word);
        }
      } catch (e) {
        console.error("error", e);
      } finally {
        this.isFetching = false;
      }
    },
    async checkEventCapacityAvailability() {
      try {
        this.maxCapacity = await eventsService.getEventCapacityAvailability(
          this.initialHour,
          this.finalHour
        );
      } catch (e) {
        console.error("error", e);
      }
    },
    async getEventCapacityList() {
      try {
        this.listMaxCapacity = await eventsService.getEventCapacityList();
      } catch (e) {
        console.error("error", e);
      }
    },
  },
  created() {
    this.getCategories();
    this.getEventCapacityList();
    this.checkEventCapacityAvailability();
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/breakpoints";

.form-container {
  margin: auto !important;
  position: relative;
  z-index: 2;
  padding: 30px;
  border-radius: 8px;
  max-width: 560px;
  width: 50vw;
  @media #{$tablet} {
    width: 70vw;
  }
  @media #{$mobile} {
    max-width: 90vw;
    width: 100%;
  }

  .modal-card-body {
    border-radius: 8px;
  }
  .button {
    background-color: #25742f;
    color: white;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
}
</style>
