<template>
  <div class="modal-container has-background-white">
    <h1 class="h1 my-3">{{dataModalComponent.name}}</h1>
    <div class="is-divider m-3"></div>
    <h4 class="has-text-grey my-3">{{dataModalComponent.initialHour | dateFormat}} / {{dataModalComponent.finalHour | dateFormat}}</h4>
    <h4 class="has-text-grey-light my-3"><span class="has-text-grey">Aforo:</span> {{dataModalComponent.capacity}} usuarios</h4>
    <h4 class="has-text-grey-light my-3"><span class="has-text-grey">Moderador:</span> {{dataModalComponent.moderator.name}} {{dataModalComponent.moderator.lastName}}</h4>
    <div class="is-divider m-3"></div>
    <h4 class="label-green my-3">Descripción</h4>
    <h4 class="has-text-grey-light">{{dataModalComponent.description}}</h4>
    <button class="button is-fullwidth mt-3" @click="$emit('editEvent', dataModalComponent)">Editar Evento</button>
    <button class="button is-fullwidth button__outlined mt-3" @click="$emit('deleteEvent', dataModalComponent); $emit('close')" >Eliminar Evento</button>
  </div>
</template>

<script>

const {DateTime} = require("luxon");

export default {
  name: "showEventDetails",
  props: {
    dataModalComponent:{
      type: Object,
      required: true
    }
  },
  filters:{
    dateFormat: function (value){
      return DateTime.fromISO(value).toFormat('HH:mm - dd LLLL yyyy', { locale: "es" })
    }
  }
}
</script>

<style lang="scss" scoped>

@import "../../assets/scss/breakpoints";

.modal-container {
  margin: auto;
  position: relative;
  z-index: 2;
  padding: 15px;
  border-radius: 8px;
  max-width: 300px;
  width: 30vw;
  .label-green{
    color: #099319;
  }
  @media #{$tablet} {
    width: 50vw;
  }
  @media #{$mobile} {
    max-width: 90vw;
    width: 100%;
  }
  .button{
    background-color: #099319;
    color: white;
    &__outlined{
      border: 1px solid #099319;
      background-color: white;
      color: #099319;
    }
  }
}
</style>
